<template>
	<div class="login"  id="constellation-net">
		<div class="form">
			<form @submit.prevent="valida_codigo">
				<div class="title text-center">
					<h1>Doble autenticación</h1>
				</div>
				<div class="form-body">
					<div class="row form-group">
						<div v-if="this.verify.auth != 4" class="col-sm-12">
							Se ha enviado un código de validación a {{ verify.contact}}
						</div>
					</div>
					<div class="row form-group">
						<div class="col-sm-12">
							<small><label for="exampleInputEmail1">Código de validación</label></small>
							<input v-model="codigo" ref="codigo_otp" type="text" name="codigo_otp" id="codigo_otp" class="form-control" autocomplete="off" placeholder="">
						</div>
					</div>
					<button type="submit" class="btn principal-btn">Validar</button>
					<template v-if="this.verify.auth != 4">
					<br />
					<div class="text-center">
						<button type="button" class="btn text-center link-underline" @click="reenviar_doble_auth">Volver a enviar código de validación</button>
					</div>
					</template>
				</div>
			</form>
		</div>
		<vue-particles color="#3DADF2"
			:particleOpacity="0.7"
			:particlesNumber="76"
			shapeType="circle"
			:particleSize="5"
			linesColor="#3DADF2"
			:linesWidth="2"
			:lineLinked="true"
			:lineOpacity="1"
			:linesDistance="150"
			:moveSpeed="4"
			:hoverEffect="true"
			hoverMode="grab"
			:clickEffect="true"
			clickMode="push">
		</vue-particles>
	</div>
</template>

<script type="text/javascript">
	import account from '@/api/account';

	export default {
		data: function() {
			return {
				verify: {
					auth: 1
					,contact: null
				}
				,codigo: null
			}
		}
		,mounted: function() {
			this.verify = this.$store.state.verify;

			this.$refs.codigo_otp.focus();
		}
		,methods: {
			valida_codigo: async function() {
				try {
					let res;
					if (this.verify.auth != 4)
						res = (await account.validar_doble_auth({codigo: this.codigo, verificationCode: this.verify.verificationCode})).data;
					else
						res = (await account.validar_codigo_tfa_auth({codigo: this.codigo, verificationCode: this.verify.verificationCode})).data;
					
					this.$log.info('res', res);

					this.$store.commit('setCredentials', res);

					if (!res.user.password_reset)
						this.$router.push({name: 'dashboard'});
					else
						this.$router.push({name: 'constelacion_password_reset'});
				}catch(e) {
					this.$log.info('error',e.response.status);

					if (e.response && e.response.status && e.response.status == 403)
						return this.$helper.showMessage('Error','Acceso denegado','error','alert');

					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,reenviar_doble_auth: async function() {
				try {
					let res = (await account.reenviar_doble_auth({verificationCode: this.verify.verificationCode})).data;
					this.$log.info('res', res);

					this.$store.commit('set',{verify: res});

					this.$helper.showMessage('Código enviado','Se reenvio el código de validación a '+res.contact,'info','alert');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
@import '@/general-styles/components/forms.scss';
@import '@/general-styles/components/particles.scss';

	.login {
    position: relative;
		z-index: 1;
		.logo {
			z-index: 1;
			img {
				height: 340px;
				width: 300px;
        margin: 1rem 0;
			}
		}
		.form {
			background: rgba(255,255,255,.69);
			padding: 2rem;
			width: 400px;
			.title {
				h1 {
					color: #fff !important;
				}
			}
			.principal-btn {
				margin: 1.5rem 0;
			}
			.showHidePassword {
				width: 30px;
				position: absolute;
				top: 33px;
				right: 21px;
				img {
					width: 100% !important;
				}
			}
			.form-body{
				padding: 0;
				margin: 0;
				background: transparent;
			}
		}
	}
	@media only screen and (max-width: 1023px){
		.login{
			.form{
				background: transparent;
			}
			.form-body{
				background: rgba(255,255,255,.69) !important;
				padding: 0 1rem !important;
				label{
					color: #4e4e4e !important;
				}
			}
		}
	}
</style>